<template>
  <div v-bind:style="styles">
    <div v-if="!viLoader">
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div >
                <div class="row" style="margin-bottom: 0%;">
                <div class="col-lg-3"> </div>
                <div class="col-lg-6" style="display: table-cell;">
                  <label class="custradio" style="margin-bottom: 0;display: table-cell;"
                  ><span style="margin-left: 15px;">All</span>
                  <input type="radio" v-model="actionType" name="schedule" value="All" />
                  <span class="checkmark" style="top: 9px;"></span>
                </label>
                    <label class="custradio" style="margin-bottom: 0;display: table-cell;"
                  ><span style="margin-left: 15px;">School Announcements</span>
                  <input type="radio" v-model="actionType" name="schedule" value="School Announcements" />
                  <span class="checkmark" style="top: 9px;"></span>
                </label>
                <label class="custradio" style="margin-bottom: 0;display: table-cell;"
                  ><span style="margin-left: 15px;">Class Announcements</span> 
                  <input v-model="actionType"
                    type="radio"
                    
                    name="schedule"
                    value="Class Announcements"
                  />
                  <span class="checkmark" style="top: 9px;"></span>
                </label> </div>
                <div class="col-lg-3"> </div>
                </div>
            
            </div>
            <div class="p-3 widjetcontent" v-if="actionType == 'All' && allAnnouncementList.length > 0">
               
               <div class="widjetcontent mt-1">
                 <div>
                    <div class="kidswrapper p-3 mb-2" style="border-radius: 35px;background-color: rgb(217 221 225);" v-for="(data, index) in allAnnouncementList" :key="index">
                     <div class="row">
                       <div class="col-12">
                         <div class="kids-details-box" style="display: grid;border-radius: 24px;">
                          Subject         :  {{ data.subject || '' }} <br> Event Type       :  {{ data.eventType || '' }} <br> Date And Time: {{ data.createdDate | dateFormat }} {{  data.createdTimeValue }}  
                          <br>
                       
                           <div :style="data.overviewHight == true ? 'overflow: hidden;' : 'height: 80px;overflow: hidden;'"
                             disabled
                             v-html="data.content"
                             
                           >
                           
                           </div> 
                           <span v-if="!data.overviewHight && data.content && data.content.length > 540"  style="margin-left: 39%;color: blue;cursor: pointer;" @click="increaseOverViewheight('all',index)">Read More</span>
                             <!-- style="margin-bottom: 1%;padding-top: 1%;"  -->
                             <!-- <a v-if="!overviewHight && data.content && data.content.length > 540" href="javascript:void(0);" style="margin-left: 39%;" @click="increaseOverViewheight">Read More</a> -->
                          <span v-if="data.overviewHight && data.content && data.content.length > 540"  style="margin-left: 39%;color: blue;cursor: pointer;" @click="reduceOverViewheight('all',index)">Read Less</span>
                            <br>
                            <span v-for="(link, index) in data.linkList" :key="index" >
                            <a v-if="link.name && link.value" href="javascript:void(0);" class="txtellipsis"   @click.prevent="navigatetolink(link.value)">Link {{ index + 1 }} :  {{ link.name }} 
                            </a>
                         </span>
                          <div class="form-row" style="width: 100%;" v-for="(doc, indexDoc) in data.documentList" :key="indexDoc">
                         <div class="form-group input-group col-md-4 mt-4">
                           <span class="has-float-label">
                             <input 
                               v-model="doc.name"
                               disabled
                               class="form-control form-input"
                               id="fpstartdateid"
                             />
                             <label  for="fpstartdateid">File Name</label>
                           </span>
                         </div>
                         <div class="form-group input-group col-md-4 mt-4">
                                                       <a :href="doc.url" target="_blank" >Download </a>

                         
                         </div>
                     </div>
                         </div>
                       
                       
                       </div>
                     </div>
                    
                   </div>
                 </div>
               </div>
             
            </div>
            <div class="p-3 widjetcontent" v-if="actionType == 'School Announcements' && schoolAnnouncementList.length > 0">
               
               <div class="widjetcontent mt-1">
                 <div>
                    <div class="kidswrapper p-3 mb-2" style="border-radius: 35px;background-color: rgb(217 221 225);" v-for="(data, index) in schoolAnnouncementList" :key="index">
                     <div class="row">
                       <div class="col-12">
                         <div class="kids-details-box" style="display: grid;border-radius: 24px;">
                          Subject         :  {{ data.subject || '' }} <br> Event Type       :  {{ data.eventType || '' }} <br> Date And Time:  {{ data.createdDate | dateAndTimeFormat }}  
                          <br>
                        
                           <div :style="data.overviewHight == true ? 'overflow: hidden;' : 'height: 80px;overflow: hidden;'"
                             disabled
                             v-html="data.content"
                             
                           >
                           
                           </div> 
                           <span v-if="!data.overviewHight && data.content && data.content.length > 540"  style="margin-left: 39%;color: blue;cursor: pointer;" @click="increaseOverViewheight('school',index)">Read More</span>
                             <!-- style="margin-bottom: 1%;padding-top: 1%;"  -->
                             <!-- <a v-if="!overviewHight && data.content && data.content.length > 540" href="javascript:void(0);" style="margin-left: 39%;" @click="increaseOverViewheight">Read More</a> -->
                          <span v-if="data.overviewHight && data.content && data.content.length > 540"  style="margin-left: 39%;color: blue;cursor: pointer;" @click="reduceOverViewheight('school',index)">Read Less</span>
                            <br>
                            <span v-for="(link, index) in data.linkList" :key="index" >
                            <a v-if="link.name && link.value" href="javascript:void(0);" class="txtellipsis"   @click.prevent="navigatetolink(link.value)">Link {{ index + 1 }} :  {{ link.name }} 
                            </a>
                         </span>
                          <div class="form-row" style="width: 100%;" v-for="(doc, indexDoc) in data.documentList" :key="indexDoc">
                         <div class="form-group input-group col-md-4 mt-4">
                           <span class="has-float-label">
                             <input 
                               v-model="doc.name"
                               disabled
                               class="form-control form-input"
                               id="fpstartdateid"
                             />
                             <label  for="fpstartdateid">File Name</label>
                           </span>
                         </div>
                         <div class="form-group input-group col-md-4 mt-4">
                                                       <a :href="doc.url" target="_blank" >Download </a>

                         
                         </div>
                     </div>
                         </div>
                       
                       
                       </div>
                     </div>
                    
                   </div>
                 </div>
               </div>
             
            </div>
            <div class="p-3 widjetcontent" v-if="actionType == 'Class Announcements' && classAnnouncementList.length > 0">
               
               <div class="widjetcontent mt-1">
                 <div>
                    <div class="kidswrapper p-3 mb-2" style="background-color: #efe6e6;" v-for="(data, index) in classAnnouncementList" :key="index">
                     <div class="row">
                       <div class="col-12">
                         <div class="kids-details-box" style="display: grid;">
                          Subject         :  {{ data.subject || '' }} <br> Event Type       :  {{ data.eventType || '' }} <br> Date And Time:  {{ data.createdDate | dateAndTimeFormat }}  
                          <br>
                        
                           <div :style="data.overviewHight == true ? 'overflow: hidden;' : 'height: 80px;overflow: hidden;'"
                             disabled
                             v-html="data.content"
                             
                           >
                           
                           </div> 
                           <span v-if="!data.overviewHight && data.content && data.content.length > 540"  style="margin-left: 39%;color: blue;cursor: pointer;" @click="increaseOverViewheight('class',index)">Read More</span>
                             <!-- style="margin-bottom: 1%;padding-top: 1%;"  -->
                             <!-- <a v-if="!overviewHight && data.content && data.content.length > 540" href="javascript:void(0);" style="margin-left: 39%;" @click="increaseOverViewheight">Read More</a> -->
                          <span v-if="data.overviewHight && data.content && data.content.length > 540"  style="margin-left: 39%;color: blue;cursor: pointer;" @click="reduceOverViewheight('class',index)">Read Less</span>
                            <br>
                            <span v-for="(link, index) in data.linkList" :key="index" >
                            <a v-if="link.name && link.value" href="javascript:void(0);" class="txtellipsis"   @click.prevent="navigatetolink(link.value)">Link {{ index + 1 }} :  {{ link.name }} 
                            </a>
                         </span>
                          <div class="form-row" style="width: 100%;" v-for="(doc, indexDoc) in data.documentList" :key="indexDoc">
                         <div class="form-group input-group col-md-4 mt-4">
                           <span class="has-float-label">
                             <input 
                               v-model="doc.name"
                               disabled
                               class="form-control form-input"
                               id="fpstartdateid"
                             />
                             <label  for="fpstartdateid">File Name</label>
                           </span>
                         </div>
                         <div class="form-group input-group col-md-4 mt-4">
                                                       <a :href="doc.url" target="_blank" >Download </a>

                         
                         </div>
                     </div>
                         </div>
                       
                       
                       </div>
                     </div>
                    
                   </div>
                 </div>
               </div>
             
            </div>
            
          </div>
        </div>
      </div>
      <div></div>
      <vi-spinner
        v-if="viLoader"
        text="Loading..."
        textColor="vi-brand-color"
        class="flex-fill h-100 vi-fs12"
        style="
          width: 100%;
          z-index: 111;
          top: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 1rem;
        "
      />
    </div>
    <div>
      
    </div>
  </div>
</template>
<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import Select from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "candidates",
  data() {
    return {
      alertList: [],
      allAnnouncementList: [],
      schoolAnnouncementList: [],
      classAnnouncementList: [],
      overviewHight: false,
      viLoader: false,
      actionType: 'All',
      visitorTrackingList: [],
      studentDetails: {},
     
      reasonList: [
          'To take back home',
          'Parents Teacher Meeting',
          'Others'
      ],
      relationList: ["Father", "Mother", "Brother","Other"],
    };
  },
  created() {
    this.initCompent();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },
  watch: {},
  methods: {
    async initCompent() {
      await this.getStudentDetails();
      this.getAlertList()

    },

   
    async getStudentDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/contact/getStudentDetaiils/${this.$route.params.id}`,
          userData.token
        );

        if (response.isSuccess) {
          this.studentDetails = response.data || {};

        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    increaseOverViewheight(type,index){
      if(type == 'school'){
        this.schoolAnnouncementList[index].overviewHight = true
      }
      if(type == 'class'){
        this.classAnnouncementList[index].overviewHight = true
      }
      if(type == 'all'){
        this.allAnnouncementList[index].overviewHight = true

      }

    },
    reduceOverViewheight(type,index){
      if(type == 'school'){
        this.schoolAnnouncementList[index].overviewHight = false
      }
      if(type == 'class'){
        this.classAnnouncementList[index].overviewHight = false
      }
      if(type == 'all'){
        this.allAnnouncementList[index].overviewHight = false

      }

    },
    //  reduceOverViewheight(){
    //   this.overviewHight = false
    // },
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",this.$route.path);
   //  this.$router.go(-1)
     this.$router.push({ path: data });
    },
      navigatetolink(value){
      window.open(value, "_blank");
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
     async getAlertList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
         this.viLoader = true
        const response = await ViService.viXGet(`/contact/getalertListForstudent?studentId=${this.$route.params.id}`,userData.token);

        if (response.isSuccess) {
          let alertList = secureUI.secureGet(response.data);
          this.allAnnouncementList = alertList

          this.schoolAnnouncementList = alertList.filter( x => x.type == 'School Announcements'),
          this.classAnnouncementList = alertList.filter( x => x.type == 'Class Announcements')
        
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false

      }
    },
  },
  components: {
    "v-select": Select,
    ViSpinner,
  },
};
</script>

<style>
@media screen {
  #printSection {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #printSection,
  #printSection * {
    visibility: visible;
  }
  #printSection {
    position: absolute;
    left: 0;
    top: 0;
  }
  #no-print,
  #no-print * {
    display: none !important;
  }
}
</style>
